import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  InputBase,
  Grid,
  useMediaQuery,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { usePresaleContract } from "../ConnectivityAss/hooks";
import logo from "../images/b.png";
import bscscoin from "../images/bscscoin.gif";
// import presale from "../images/presale.gif";
import bnbLogo from "../images/bnblogo.svg";
import Loading from "../loading";
import { toast } from "react-toastify";
import { AppContext } from "../utils";
import { parseUnits } from "@ethersproject/units";
import TimerCountDown from "../Timer";
import { styled } from "@mui/styles";

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: "100%",
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#d1d1d1",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: "#f8cb6e",
  },
}));

function Presale() {
  const { account, signer } = useContext(AppContext);
  const preSaleContract = usePresaleContract(signer);
  const matches = useMediaQuery("(max-width:700px)");
  const [amount, setamount] = useState("");
  const [tokens, settokens] = useState("0");
  const [loading, setloading] = useState(false);
  const [hardCap, sethardCap] = useState(4000);
  const [sold, setsold] = useState(0);
  const [progress, setprogress] = useState(0);

  const init = async () => {
    try {
      let tokenReceive = 6002 * +amount;
      settokens(tokenReceive);
      console.log(tokenReceive, "tokenReceive");
    } catch (error) {}
  };
  useEffect(() => {
    if (amount) {
      init();
    }
  }, [amount]);
  // useEffect(() => {
  //   const initSoftCap = async () => {
  //     try {
  //       const softCap = await preSaleContract.amountRaised();
  //       let raised = +formatUnits(softCap.toString());
  //       let prog = (+raised / +hardCap) * 100;
  //       setsold(raised);
  //       setprogress(+prog);
  //     } catch (error) {}
  //   };
  //   initSoftCap();
  // }, []);
  const buyHadler = async () => {
    if (account) {
      if (!amount) {
        toast.error("Error! Please enter a amount");
      } else if (isNaN(amount) || +amount < 0) {
        toast.error("Error! Please enter a valid amount");
      } else if (+amount == 0) {
        toast.error("Error!  Please enter a valid amount");
      } else if (+amount > 0 && +amount < 0.000169) {
        toast.error("Error! Minimum amount is 0.000169 BNB");
      } else if (+amount !== 0 && +amount > 20) {
        toast.error("Error! Maximum amount is 20 BNB");
      } else {
        try {
          setloading(true);
          console.log(
            parseUnits(amount.toString()).toString(),
            "parseUnits(amount.toString())"
          );
          const tx = await preSaleContract.buyTokens(account, {
            value: parseUnits(amount.toString()).toString(),
          });
          await tx.wait();
          toast.success("Success! Transaction confirmed.");
          setloading(false);
          window.location.reload();
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            toast.error(error?.data?.message);
          } else {
            toast.error(error?.message);
          }
        }
      }
    } else {
      toast.error("Error! Please connect your wallet.");
    }
  };

  return (
    <Box pt={5} pb={10} position="relative" zIndex={1}>
      <Loading loading={loading} />

      <Container maxWidth="md">
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box
            mb={2}
            fontWeight="700"
            fontSize="25px"
            color="#ffffff"
            fontFamily="Secular One"
            textAlign="center"
          >
           <img
                style={{ marginRight: "5px", marginBottom: "3px" }}
                width="225px"
                height="225px"
                src={bscscoin}
                alt=""
                 />
                 <h3 style={{color:'yellow', fontSize:'30px'}}>Presale Now On Sale </h3>
          <h3>Price : $0.1</h3>
           
          </Box>
          <TimerCountDown />
          {/* <div className="price">
          <h3>Phase 2 Price - $0.07</h3></div> */}
          <Grid mt={5} container spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                width="100%"
                height="100%"
                boxShadow="#ffffff 0px 0px 10px 1px"
                style={{
                  background: "#4c4042",
                }}
                borderRadius="0px"
                borderTop="20px solid #f8cb6e"
                borderBottom="20px solid #f8cb6e"
                borderLeft="10px solid #f8ca6ece"
                borderRight="10px solid #f8ca6ece"
                display="flex"
                flexDirection="column"
                p={2}
              >
                <Box
                  fontWeight="700"
                  fontSize="25px"
                  color="#ffffff"
                  fontFamily="Secular One"
                  textAlign="center"
                >
                  Buy OST
                </Box>

                <Box
                  mt={2}
                  mb={2}
                  style={{
                    boxShadow: "#ffffff 0px 0px 8px 1px",
                    background: "transparent",
                    paddingLeft: 15,
                    borderRadius: "0px",
                  }}
                  pb={2}
                  pt={1}
                >
                  <Box
                    style={{
                      color: "#ffffff",
                      fontFamily: "Chakra Petch",
                      fontWeight: "500",
                      fontSize: "18px",
                      marginTop: "10px",
                      width: "100%",
                      paddingRight: "15px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    From:{" "}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-beteen"
                    alignItems="center"
                  >
                    <InputBase
                      style={{
                        color: "#ffffff",
                        fontFamily: "Chakra Petch",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "10px",
                        width: "100%",
                        paddingRight: "15px",
                      }}
                      fullWidth
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      placeholder="Enter BNB"
                      value={amount}
                      onChange={(e) => {
                        setamount(e.target.value);
                      }}
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      borderRadius="0px"
                      height={matches ? "35px" : "auto"}
                      pl={matches ? 1 : 3}
                      pr={matches ? 2 : 4}
                      py={1}
                      mr={2}
                      style={{
                        color: "#ffffff",
                        fontFamily: "Chakra Petch",
                        fontWeight: "500",
                        fontSize: "16px",
                        marginTop: "10px",
                        background: "transparent",
                        boxShadow: "#f8ca6ece 0px 0px 8px 1px",
                        borderBottom: "5px solid #f8cb6e",
                      }}
                    >
                      {" "}
                      <img
                        style={{ marginRight: "5px", marginBottom: "3px" }}
                        width="25px"
                        height="25px"
                        src={bnbLogo}
                        alt=""
                      />{" "}
                      BNB
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    boxShadow: "#ffffff 0px 0px 8px 1px",
                    backgroundColor: "transparent",
                    borderRadius: "0px",
                    marginTop: 10,
                  }}
                  py={2}
                  px={2}
                >
                  <Box
                    mb={1}
                    style={{
                      color: "#ffffff",
                      fontFamily: "Chakra Petch",
                      fontWeight: "500",
                      fontSize: "18px",
                      width: "100%",
                      paddingRight: "15px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    To:{" "}
                  </Box>

                  <Box
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        color: "#ffffff",
                        fontFamily: "Chakra Petch",
                      }}
                      className="heading"
                    >
                      {tokens}
                    </span>
                    <Box
                      display="flex"
                      alignItems="center"
                      borderRadius="0px"
                      height={matches ? "35px" : "auto"}
                      pl={matches ? 1 : 3}
                      pr={matches ? 2 : 4}
                      py={1}
                      style={{
                        color: "#ffffff",
                        fontFamily: "Chakra Petch",
                        fontWeight: "500",
                        fontSize: "16px",
                        marginTop: "10px",
                        boxShadow: "#f8ca6ece 0px 0px 8px 1px",
                        background: "transparent",
                        borderBottom: "5px solid #f8cb6e",
                      }}
                    >
                      {" "}
                      <img
                        style={{ marginRight: "5px", marginBottom: "3px" }}
                        width="20px"
                        height="20px"
                        src={logo}
                        alt=""
                      />{" "}
                      OST
                    </Box>
                  </Box>
                </Box>

                <Box mt={5} display="flex" flexDirection="column">
                  <Box textAlign="center" width="100%">
                    <Button
                      style={{
                        background: "transparent",
                        background: "transparent",
                        boxShadow: "#ffffff 0px 0px 8px 1px",
                        fontSize: "18px",
                        borderRadius: "0px",
                        width: "176px",
                        height: "45px",
                        color: "#ffffff",
                        textTransform: "capitalize",
                        fontWeight: "500",
                        borderBottom: "5px solid #f8cb6e",
                      }}
                      onClick={() => buyHadler()}
                    >
                      Buy
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                width="100%"
                height="100%"
                boxShadow="#ffffff 0px 0px 10px 1px"
                style={{
                  background: "#4c4042",
                }}
                borderRadius="0px"
                display="flex"
                flexDirection="column"
                borderTop="20px solid #f8cb6e"
                borderBottom="20px solid #f8cb6e"
                borderLeft="10px solid #f8ca6ece"
                borderRight="10px solid #f8ca6ece"
                p={2}
              >
                {/* <BorderLinearProgress
                  style={{ height: 20, marginTop: 10 }}
                  variant="determinate"
                  value={progress}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    fontFamily="Secular One"
                    fontSize="15px"
                    fontWeight={700}
                    color="#ffffff"
                  >
                    {parseFloat(+sold).toFixed(4)} BNB
                  </Box>
                  <Box
                    fontFamily="Secular One"
                    fontSize="15px"
                    fontWeight={700}
                    color="#ffffff"
                  >
                    {" "}
                    {hardCap} BNB
                  </Box>
                </Box> */}
                <Box
                  fontWeight="700"
                  fontSize="25px"
                  color="#ffffff"
                  fontFamily="Secular One"
                  textAlign="center"
                >
                  Token Info
                </Box>
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #f8cb6e"
                  pb={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    Token Name
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    OST Oil Sand
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #f8cb6e"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    Token Symbol
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    OST
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #f8cb6e"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    Token Decimals
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    18
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #f8cb6e"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    Tokens Per BNB
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    6002
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #f8cb6e"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    Min Contribute
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    0.001 BNB{" "}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #f8cb6e"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    Max Contribute
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    10 BNB{" "}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #f8cb6e"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#ffffff"
                    fontFamily="Chakra Petch"
                  >
                    Token Contract
                  </Box>
                  <a
                  // href="https://bscscan.com/address/0x31f6ce8fc0ddfbc736d824b7ad8224c81ba1103d"
                    href="https://bscscan.com/address/0x391A011CDDb2CB7fdFf4cf333fe3Cd9390b46965#code"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      fontWeight="600"
                      borderRadius="8px"
                      fontSize="16px"
                      color="#ffffff"
                      fontFamily="Chakra Petch"
                      textTransform="wrap"
                      overflowWrap= "break-word"
                    >
                      0x391A011CD...0b46965
                    </Box>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="xl">
      <h2 className="h2_class" id="Howto_buy">HOW TO BUY</h2>
      <div className='How_details'>
      <Grid mt={5} container spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <h3>Desktop View</h3>
            <div className="desktop_view">
            <p style={{textAlign:'left', paddingLeft:'40px', fontSize:'20px'}}>Step 1</p>
            <ul>
              <li>Click on <em><quote>'Connect'</quote></em></li>
              <li>Choose the wallet where you have your BNB funds, <em>Metamask or WalletConnect</em></li>
              <li>When prompted, click on <em>confirm.</em></li>
              <li>Enter the amount of OST you want to buy with BNB </li>
            </ul>
            </div>

            {/* <img src={presale} width='600px' height='300px'></img> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <h3>Mobile</h3>
            <div className="desktop_view">
            <p  style={{textAlign:'left', paddingLeft:'40px', fontSize:'20px'}}>Step 1</p>
            <p style={{textAlign:'left', paddingLeft:'40px', fontSize:'20px', color:'white'}}>Follow up with the steps explained in Desktop View. </p>
            <p style={{textAlign:'left', paddingLeft:'40px', fontSize:'20px', color:'white'}}>If you have any challenge connecting to Trustwallet, follow this step 2.</p>
            <p  style={{textAlign:'left', paddingLeft:'40px',paddingTop:'15px', fontSize:'20px'}}>Step 2</p>
            <ul>
              <li>Go to your <em><b>Trustwallet</b></em>  click on <em><b>Discover</b></em></li>
              <li>Type in <a href=""></a><em><b>website link</b></em>. a prompt shows, choose network and click <em><b>Connect</b></em></li>
              <li>When prompted, click on <em>confirm.</em></li>
              <li>Once connected, enter the amount of OST you want to buy with BNB </li>
            </ul>
            </div>
            </Grid>
            </Grid>
            </div>
            </Container>
  {/* <Container maxWidth="md">
   <div className='How_details'>
    <div className='row'>
    <h3>Desktop View</h3>
      <div className='col-lg-6 desktop_view'>
      <video src='../images/pretest.webm'></video>
      </div>
    </div>
   </div>
</Container> */}

    </Box>


  );
}

export default Presale;
