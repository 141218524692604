import React, { useContext } from "react";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import { AppContext } from "../utils";
import logo from "../images/logo.png";

export default function Header() {
  const { account, connect, disconnect } = useContext(AppContext);

  const matches = useMediaQuery("(max-width:960px)");

  return (
    <>
      <Box
        style={{
          background: "transparent",
          zIndex: "100",
        }}
        position="relative"
        height="92px"
        width="100%"
        pt={2}
      >
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#000000",
                fontSize: "20px",
              }}
            >
              <img width="100%" height="100px" src={logo} alt="" />
            </Box>

            {account ? (
              <Box
                width="130px"
                height="42px"
                borderRadius="0px"
                sx={{
                  cursor: "pointer",
                  boxShadow: "#ffffff 0px 0px 8px 1px",
                  background: "transparent",
                  borderBottom: "5px solid #f8cb6e",
                }}
                display="flex"
                fontFamily="Chakra Petch"
                justifyContent="center"
                alignItems="center"
                color="#ffffff"
                fontWeight="500"
                fontSize="16px"
                onClick={() => disconnect()}
              >
                {account.slice(0, 4) + "..." + account.slice(-4)}
              </Box>
            ) : (
              <Box
                zIndex={1}
                style={{
                  cursor: "pointer",
                  boxShadow: "#ffffff 0px 0px 8px 1px",
                  background: "transparent",
                  borderBottom: "5px solid #f8cb6e",
                }}
                width="130px"
                height="42px"
                fontFamily="Chakra Petch"
                fontWeight="500"
                borderRadius="0px"
                fontSize="20px"
                color="#ffffff"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={() => connect()}
              >
                Connect
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}
