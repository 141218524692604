import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Box, useMediaQuery } from "@mui/material";

export default function TimerCountDown() {
  const matches = useMediaQuery("(max-width:700px)");

  let interval = useRef();
  // console.log("time", time);

  const [countTime, setCountDateTime] = useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    time_seconds: 0,
  });

  const startTime = async () => {
    let countDownDate = moment.utc("Dec 20 2022, 11:00:00").format("x");
    let interval = setInterval(() => {
      let now = moment.utc().format("x");
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };
  useEffect(() => {
    startTime();
  }, []);
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          mr={matches ? 1 : 5}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            borderRadius="0px"
            width={matches ? "70px" : "110px"}
            style={{
              WebkitTextFillColor: "#ffffff",
            }}
            px={2}
            py={1}
            border="3px solid #f8cb6e"
            borderBottom="15px solid #f8cb6e"
            fontSize={matches ? "30px" : "60px"}
            fontWeight="700"
            textAlign="center"
            fontFamily="Secular One"
            bgcolor="#4c4042"
            mb={2}
          >
            {countTime.time_days}
          </Box>
          <Box
            width={matches ? "70px" : "110px"}
            color="#ffffff"
            fontSize={matches ? "15px" : "30px"}
            fontWeight="500"
            textAlign="center"
            fontFamily="Secular One"
          >
            Days
          </Box>
        </Box>
        <Box
          mr={matches ? 1 : 5}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            borderRadius="0px"
            width={matches ? "70px" : "110px"}
            style={{
              WebkitTextFillColor: "#ffffff",
            }}
            px={2}
            py={1}
            border="3px solid #f8cb6e"
            borderBottom="15px solid #f8cb6e"
            fontSize={matches ? "30px" : "60px"}
            fontWeight="700"
            textAlign="center"
            fontFamily="Secular One"
            bgcolor="#4c4042"
            mb={2}
          >
            {countTime.time_Hours}
          </Box>
          <Box
            width={matches ? "70px" : "110px"}
            color="#ffffff"
            fontSize={matches ? "15px" : "30px"}
            fontWeight="500"
            textAlign="center"
            fontFamily="Secular One"
          >
            Hours
          </Box>
        </Box>
        <Box
          mr={matches ? 1 : 5}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            borderRadius="0px"
            width={matches ? "70px" : "110px"}
            style={{
              WebkitTextFillColor: "#ffffff",
            }}
            px={2}
            py={1}
            border="3px solid #f8cb6e"
            borderBottom="15px solid #f8cb6e"
            fontSize={matches ? "30px" : "60px"}
            fontWeight="700"
            fontFamily="Secular One"
            textAlign="center"
            bgcolor="#4c4042"
            mb={2}
          >
            {countTime.time_Minusts}
          </Box>
          <Box
            width={matches ? "70px" : "110px"}
            color="#ffffff"
            fontSize={matches ? "15px" : "30px"}
            fontWeight="500"
            textAlign="center"
            fontFamily="Secular One"
          >
            Minutes
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            borderRadius="0px"
            width={matches ? "70px" : "110px"}
            style={{
              WebkitTextFillColor: "#ffffff",
            }}
            px={2}
            py={1}
            border="3px solid #f8cb6e"
            borderBottom="15px solid #f8cb6e"
            fontSize={matches ? "30px" : "60px"}
            fontWeight="700"
            textAlign="center"
            fontFamily="Secular One"
            bgcolor="#4c4042"
            mb={2}
          >
            {countTime.time_seconds}
          </Box>
          <Box
            width={matches ? "70px" : "110px"}
            color="#ffffff"
            fontSize={matches ? "15px" : "30px"}
            fontWeight="500"
            textAlign="center"
            fontFamily="Secular One"
          >
            Seconds
          </Box>
        </Box>
      </Box>
    </>
  );
}
