import React from "react";
import { Dialog, DialogContent, Box, Slide } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function NetworkChange({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  const networkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        // params: [{ chainId: "0x38" }], //BSC Mainnet
        params: [{ chainId: "0x61" }], //BSC Testnet
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent
            className="dialoge__content__section"
            style={{ background: "#55aaff63" }}
          >
            <Box component="h3" color="#000000">
              <Box component="span" color="red" fontSize="30px">
                Error!
              </Box>{" "}
              You are on wrong network please switch your network.
            </Box>
            <Box align="center">
              <Box
                width="130px"
                height="42px"
                borderRadius="0px"
                sx={{
                  cursor: "pointer",
                  boxShadow: "#f8cb6e 0px 0px 8px 1px",
                  background: "transparent",
                  borderBottom: "5px solid #f8cb6e",
                }}
                display="flex"
                fontFamily="Chakra Petch"
                justifyContent="center"
                alignItems="center"
                color="#000000"
                fontWeight="500"
                fontSize="16px"
                onClick={() => networkHandler()}
              >
                Switch Network
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default NetworkChange;
